<template>
  <div class="main-box">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        row-key="id"
        style="width: 100%;"
        size="mini"
        default-expand-all>
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="title"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="ordering"
          label="排序">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editMenu(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog :title="menuTitle" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="分组名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.ordering" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '添加分组',
      dialogFormVisible: false,
      form: {
        id: 0,
        ordering: '',
        title: ''
      },
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      formLabelWidth: '120px',
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getList() {
      this.showLoading()
      var param = {
        page: this.search.current_page,
        page_size: this.search.limit,
        keywords: this.search.keywords
      }
      this.$api.member.memberGroupIndex(param, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.tableData = res.data.data
          this.search.current_page = res.data.current_page
          this.search.total = res.data.total
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    editMenu(item) {
      this.form = item
      this.menuTitle = '编辑分组'
      this.dialogFormVisible = true
    },
    addMenu() {
      this.form.id = 0
      this.form.ordering = ''
      this.form.title = ''
      this.menuTitle = '添加分组'
      this.dialogFormVisible = true
    },
    saveMenu() {
      var param = {
        id: this.form.id,
        title: this.form.title,
        ordering: this.form.ordering
      }
      if(this.form.id == 0) {
        this.$api.member.memberGroupAdd(param,res => {
          if(res.errcode == 0) {
            this.dialogFormVisible = false
            this.getList()
            this.success(res.errmsg)
          } else {
            this.fail(res.errmsg)
          }
        })
      } else {
        this.$api.member.memberGroupEdit(param,res => {
          if(res.errcode == 0) {
            this.dialogFormVisible = false
            this.getList()
            this.success(res.errmsg)
          } else {
            this.fail(res.errmsg)
          }
        })
      }
    },
    delMenu() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if(sel.length<1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.member.memberGroupDel({ids: ids},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
